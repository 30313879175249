<template>
    <section
        class="o-flexible-module"
        :class="{ 'o-flexible-module--flush': settings.spacing == 'flush' }">
        <div class="grid-container" v-if="settings.showComponentTitle">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">
                        Banner
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="slides"
            class="c-banner__container s-swiper js-banner"
            :class="{ 'has-slider js-cursor-scroll': slides.length > 1 }">
            <swiper-container
                v-if="slides.length > 1"
                :slides-per-view="1"
                @slidechangetransitionend="updatePagination">
                <swiper-slide
                    v-for="(banner, index) in slides"
                    :key="index"
                    :data-theme="banner.theme">
                    <div
                        class="c-banner"
                        :class="`c-banner--${banner.theme}`, {
                            'c-banner--shallow': settings.enableShallowBanner,
                            'has-extra-padding': settings.enableExtraPadding,
                            'has-video': banner.theme == 'video'
                        }">
                        <div
                            v-if="banner.theme == 'image' || banner.theme == 'video'"
                            class="c-banner__media"
                            :class="{
                                'is-youtube': banner.videoSource == 'youtube',
                                'is-vimeo': banner.videoSource == 'vimeo',
                                'js-parallax__wrapper': banner.theme == 'image'
                            }"
                            data-aos="fade-in"
                            data-aos-duration="2000">
                            <div
                                v-if="(banner.theme == 'image' || banner.theme == 'video') && banner.settings.backgroundOverlay.showBackgroundOverlay == true"
                                class="c-banner__media-overlay"
                                :style="`background-color: rgba(0, 0, 0, ${banner.settings.backgroundOverlay.backgroundOverlayStrength});`">
                            </div>

                            <img
                                v-if="banner.theme == 'image'"
                                class="js-parallax"
                                :srcset="banner.image.srcSet"
                                :sizes="banner.image.sizes"
                                :src="banner.image.sourceUrl"
                                :width="banner.image.mediaDetails.width"
                                :height="banner.image.mediaDetails.height" />

                            <iframe
                                v-if="banner.theme == 'video' && banner.videoSource == 'youtube'"
                                class="c-banner__embed is-youtube"
                                :src="`https://www.youtube-nocookie.com/embed/${$convertVideoId(banner.video)}?autoplay=1&controls=0&playlist=${$convertVideoId(banner.video)}&loop=1&rel=0&enablejsapi=1&iv_load_policy=3&mute=1`"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen>
                            </iframe>

                            <div
                                v-else-if="banner.theme == 'video' && banner.videoSource == 'vimeo'"
                                class="c-banner__embed is-vimeo">
                                <VimeoPlayer
                                    :video-id="banner.vimeoVideoId"
                                    autoplay="true"
                                    loop="true"
                                    controls="false"
                                    :options="{
                                        background: true,
                                        dnt: true,
                                        muted: true,
                                        responsive: true
                                    }">
                                </VimeoPlayer>
                            </div>

                            <video autoplay loop muted
                                v-if="banner.theme == 'video' && banner.videoSource == 'local'"
                                class="c-banner__embed is-local">
                                <source :src="banner.localVideo.link" :type="banner.localVideo.mimeType" />

                                <a :href="banner.localVideo.link" download>Download</a> this video
                            </video>
                        </div>

                        <div class="c-banner__wrapper" >
                            <div class="grid-container">
                                <div class="grid-x grid-margin-x">
                                    <div class="small-12 medium-8 large-4 cell">
                                        <div
                                            v-if="banner.logoImage?.sourceUrl"
                                            class="c-banner__logo-mini">
                                            <img
                                                :alt="banner.logoImage.altText"
                                                :sizes="banner.logoImage.sizes"
                                                :src="banner.logoImage.sourceUrl"
                                                :srcset="banner.logoImage.srcSet"
                                                :title="banner.logoImage.title"
                                                width="200" />
                                        </div>

                                        <h1
                                            v-if="banner.settings.headingLevel == 'h1'"
                                            class="c-banner__title">
                                            {{ banner.title }}
                                        </h1>

                                        <h2
                                            v-else-if="banner.settings.headingLevel == 'h2'"
                                            class="c-banner__title">
                                            {{ banner.title }}
                                        </h2>

                                        <div
                                            v-else
                                            class="c-banner__title">
                                            {{ banner.title }}
                                        </div>

                                        <div
                                            v-if="banner.content"
                                            class="c-banner__content s-wp-cms"
                                            v-html="banner.content">
                                        </div>

                                        <NuxtLink
                                            v-if="banner.link && banner.link.url"
                                            :to="$convertUrl(banner.link.url)"
                                            class="c-banner__button"
                                            :target="banner.link.target">
                                            {{ banner.link.title }}
                                        </NuxtLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper-container>

            <div
                v-else
                class="c-banner"
                :class="`c-banner--${slides[0].theme}`, {
                    'c-banner--shallow': settings.enableShallowBanner,
                    'has-extra-padding': settings.enableExtraPadding,
                    'has-video': slides[0].theme == 'video'
                }">
                <div
                    v-if="slides[0].theme == 'image' || slides[0].theme == 'video'"
                    class="c-banner__media"
                    :class="{
                        'is-youtube': slides[0].videoSource == 'youtube',
                        'is-vimeo': slides[0].videoSource == 'vimeo',
                        'js-parallax__wrapper': slides[0].theme == 'image'
                    }"
                    data-aos="fade-in"
                    data-aos-duration="2000">
                    <div
                        v-if="(slides[0].theme == 'image' || slides[0].theme == 'video') && slides[0].settings.backgroundOverlay.showBackgroundOverlay == true"
                        class="c-banner__media-overlay"
                        :style="`background-color: rgba(0, 0, 0, ${slides[0].settings.backgroundOverlay.backgroundOverlayStrength});`">
                    </div>

                    <img
                        v-if="slides[0].theme == 'image'"
                        class="js-parallax"
                        :srcset="slides[0].image.srcSet"
                        :sizes="slides[0].image.sizes"
                        :src="slides[0].image.sourceUrl"
                        :width="slides[0].image.mediaDetails ? slides[0].image.mediaDetails.width : null"
                        :height="slides[0].image.mediaDetails ? slides[0].image.mediaDetails.height : null" />

                    <iframe
                        v-if="slides[0].theme == 'video' && slides[0].videoSource == 'youtube'"
                        class="c-banner__embed is-youtube"
                        :src="`https://www.youtube-nocookie.com/embed/${$convertVideoId(slides[0].video)}?autoplay=1&controls=0&playlist=${$convertVideoId(slides[0].video)}&loop=1&rel=0&enablejsapi=1&iv_load_policy=3&mute=1`"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                    </iframe>

                    <div
                        v-else-if="slides[0].theme == 'video' && slides[0].videoSource == 'vimeo'"
                        class="c-banner__embed is-vimeo">
                        <VimeoPlayer
                            :video-id="slides[0].vimeoVideoId"
                            autoplay="true"
                            loop="true"
                            controls="false"
                            :options="{
                                background: true,
                                dnt: true,
                                muted: true,
                                responsive: true
                            }">
                        </VimeoPlayer>
                    </div>

                    <video autoplay loop muted
                        v-if="slides[0].theme == 'video' && slides[0].videoSource == 'local'"
                        class="c-banner__embed is-local">
                        <source :src="slides[0].localVideo.link" :type="slides[0].localVideo.mimeType" />

                        <a :href="slides[0].localVideo.link" download>Download</a> this video
                    </video>
                </div>

                <div class="c-banner__wrapper" >
                    <div class="grid-container">
                        <div class="grid-x grid-margin-x">
                            <div class="small-12 medium-8 large-4 cell">
                                <div
                                    v-if="slides[0].logoImage?.sourceUrl"
                                    class="c-banner__logo-mini">
                                    <img
                                        :alt="banner.logoImage.altText"
                                        :sizes="banner.logoImage.sizes"
                                        :src="banner.logoImage.sourceUrl"
                                        :srcset="banner.logoImage.srcSet"
                                        :title="banner.logoImage.title"
                                        width="200" />
                                </div>

                                <h1
                                    v-if="slides[0].settings.headingLevel == 'h1'"
                                    class="c-banner__title">
                                    {{ slides[0].title }}
                                </h1>

                                <h2
                                    v-else-if="slides[0].settings.headingLevel == 'h2'"
                                    class="c-banner__title">
                                    {{ slides[0].title }}
                                </h2>

                                <div
                                    v-else
                                    class="c-banner__title">
                                    {{ slides[0].title }}
                                </div>

                                <div
                                    v-if="slides[0].content"
                                    class="c-banner__content s-wp-cms"
                                    v-html="slides[0].content">
                                </div>

                                <NuxtLink
                                    v-if="slides[0].link"
                                    :to="$convertUrl(slides[0].link.url)"
                                    class="c-banner__button"
                                    :target="slides[0].link.target">
                                    {{ slides[0].link.title }}
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="slides.length > 1 || !settings.disableCisilionLogo"
                class="c-banner__ancillary">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 cell">
                            <div class="c-banner__pagination-wrapper">
                                <div
                                    v-if="slides.length > 1"
                                    class="c-banner__pagination js-banner__pagination" >
                                    <template
                                        v-for="(banner, index) in slides"
                                        :key="index">
                                        <button
                                            class="s-swiper__bullet js-swiper__bullet"
                                            :class="{ 'is-active': index == 0 }"
                                            type="button"
                                            :aria-label="`Go to Slide ${index + 1}`"
                                            :data-slide="`${index}`"
                                            @click="goToSlide($event, index, banner.theme)">
                                        </button>
                                    </template>
                                </div>

                                <div
                                    v-if="!settings.disableCisilionLogo"
                                    class="c-banner__logo">
                                    <SvgoLogoIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const { $instantiateSwiper } = useNuxtApp();

    const props = defineProps({
        slides: {
            type: Object,
            required: true
        },
        settings: {
            enableShallowBanner: {
                type: Boolean,
                required: true
            },
            enableExtraPadding: {
                type: Boolean,
                required: true
            },
            disableCisilionLogo: {
                type: Boolean,
                required: true
            },
            showComponentTitle: {
                type: Boolean,
                required: true,
            },
            spacing: {
                type: String,
                required: true
            }
        }
    });

    // Handle slider pagination bullet clicks
    const goToSlide = (event, slide, theme) => {
        const $target = event.target;
        const $bullets = $target.closest('.js-banner__pagination').querySelectorAll('.js-swiper__bullet');
        const $parent = $target.closest('.js-banner');
        const swiperEl = $parent.querySelectorAll('swiper-container')[0];

        $bullets.forEach(function (el) {
            // Remove 'is-active' class from all bullets
            el.classList.remove('is-active');

            // Update bullet theme if necessary
            if (theme == 'obsidian' || theme == 'obsidian-mineral' || theme == 'image') {
                el.classList.add('is-alabaster');
            } else if (theme == 'teal') {
                el.classList.add('is-mist');
            } else {
                el.classList.remove('is-alabaster');
                el.classList.remove('is-mist');
            };
        });

        // Swipe to clicked slide
        swiperEl.swiper.slideToLoop(slide);

        // Add 'is-active' class to clicked bullet
        $target.classList.add('is-active');
    };

    const updatePagination = (e) => {
        // Save active slide index and theme
        const index = e.detail[0].realIndex;
        const theme = e.detail[0].slides[index].dataset.theme;

        // Get pagination bullets
        const $bullets = e.target.closest('.js-banner').querySelectorAll('.js-swiper__bullet');

        // Loop through pagination bullets
        $bullets.forEach(function (el) {
            // Remove 'is-active' class from all bullets
            el.classList.remove('is-active');

            // Update bullet theme if necessary
            if (theme == 'obsidian' || theme == 'obsidian-mineral' || theme == 'image') {
                el.classList.add('is-alabaster');
            } else if (theme == 'teal') {
                el.classList.add('is-mist');
            } else {
                el.classList.remove('is-alabaster');
                el.classList.remove('is-mist');
            };

            // Add 'is-active' class to new active slide bullet
            if (el.dataset.slide == index) {
                el.classList.add('is-active');
            };
        });
    };

    onMounted(() => {
        const $image = document.getElementsByClassName('js-parallax');
        new simpleParallax($image, {delay: .6, transition: 'cubic-bezier(0,0,0,1)'});
    });
</script>
